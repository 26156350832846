<template>
<div>
  <b-modal
    id="modalbasic"
    v-b-modal.modal-center
    ref="modalbasic"
    @hide="hide()"
    v-model="isVisible"
    :title="this.title"
    @cancel="close()"
    @close="close()"
    :no-close-on-backdrop="true"
    size="lg">
    <messages />
    <b-row>
      <b-col>
          <confirmation ref="Confirmation"></confirmation>
          <b-form>
            <!-- Quote Amount -->
            <b-row>
              <b-col>
                <b-form-group
                  label="Quote Amount:"
                  label-for="quoteAmount"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <b-form-input
                    id="quoteAmount"
                    class="col-sm-6"
                    v-model.trim="$v.leadQuote.maskedQuoteAmount.$model"
                    :state="!$v.leadQuote.maskedQuoteAmount.$error"
                    :disabled="isDisableFields"
                    v-mask="currencyMask"
                  >
                  </b-form-input>
                  <div
                  class="text-danger"
                  :state="!$v.leadQuote.maskedQuoteAmount.$error"
                  v-if="!($v.leadQuote.maskedQuoteAmount.required && $v.leadQuote.maskedQuoteAmount.minLength)"
                >
                  Quote Amount is required!
                </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- End of Quote Amount -->

            <!--Quote Notes-->
            <b-row>
              <b-col>
                <b-form-group
                  label="Notes:"
                  label-for="quoteNotes"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <b-form-textarea
                    id="quoteNotes"
                    :class="{'col-sm-10':true,
                    'disable-textarea-resize':isDisableFields
                    }"
                    rows="3"
                    v-model.trim="$v.leadQuote.notes.$model"
                    :state="!$v.leadQuote.notes.$error"
                    :disabled="isDisableFields"
                  ></b-form-textarea>
                   <div
                  class="text-danger"
                  :state="!$v.leadQuote.notes.$error"
                  v-if="!$v.leadQuote.notes.maxLength"
                >
                  Maximum Characters Allowed 500!
                </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Quote Notes-->

            <!--RFP Required-->
            <!-- <b-row>
              <b-col>
                <b-form-group
                  label="RFP Required:"
                  label-for="rfpRequired"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <b-form-radio-group
                    id="rfpRequired"
                    class="col-sm-10"
                    :options="options"
                    v-model="$v.leadQuote.rfpRequired.$model"
                    :state="!$v.leadQuote.rfpRequired.$error"
                    :disabled="isDisableFields"
                  >
                  </b-form-radio-group>
                  <div
                  class="text-danger"
                  :state="!$v.leadQuote.rfpRequired.$error"
                  v-if="!$v.leadQuote.rfpRequired.required"
                >
                  RFP Required is required!
                </div>
                </b-form-group>
              </b-col>
            </b-row> -->
            <!--End of RFP Required-->

            <!--Active-->
            <b-row>
              <b-col>
                <b-form-group
                  label="Active:"
                  label-for="active"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <switches
                    id="active"
                    v-model="leadQuote.active"
                    color="primary"
                    theme="custom"
                    class="vue-switcher-small alignWithLabel"
                    :disabled="isDisableFields"
                  ></switches>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End Active-->

            <!--Quote Recipients-->
            <b-row>
              <b-col>
                <b-form-group
                  label="Quote Recipients"
                  label-for="quoteRecipients"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <multiselect
                    id="quoteRecipients"
                    v-model="myRecipient"
                    :options="quoteRecipients"
                    :close-on-select="true"
                    placeholder="Select Recipients..."
                    label="displayData"
                    track-by="recipientID"
                    select-label="Add"
                    deselect-label="Remove"
                    selected-label=""
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-button pill variant="primary" @click="addSelectedRecipient()">
                  Add Recipient
                </b-button>
              </b-col>
            </b-row>
            <!--Quote Recipients-->

            <!--External Quote Recipients-->
            <b-card title="External Quote Recipients">
              <div v-for="(recipient, index) in recipientsSelected"
                :key="index"
                >
                <b-row>
                  <b-col lg="3">
                    <b-form-group>
                      <b-form-input
                        placeholder="Email"
                        v-model="recipient.email"
                        :state="!$v.recipientsSelected.$each[index].email.$error"
                      ></b-form-input>
                      <div
                        class="text-danger"
                        v-if="!$v.recipientsSelected.$each[index].email.required"
                      >
                        Email is required
                      </div>
                      <div
                        class="text-danger"
                        v-else-if="!$v.recipientsSelected.$each[index].email.emailValidation"
                      >
                        Enter a valid email address
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3">
                    <b-form-group>
                      <b-button variant="danger" @click="deleteEvent(recipient.recipientID)"
                        >Delete</b-button
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
              <b-col>
                <b-button
                  pill
                  variant="primary"
                  class="top-right-button mr-2 float-left"
                  @click="addExternalRecipiant()"
                  >Add External Recipient</b-button
                >
              </b-col>
              </b-row>
            </b-card>
            <!--External Quote Recipients-->

            <!--Quote Data Sent-->
            <b-row>
              <b-col>
                <b-form-group
                  label="Quote Date Sent:"
                  label-for="quoteDateSent"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                <div class="alignWithLabel">{{this.date}}</div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Quote Data Sent-->

            <!--Quote Data Sent-->
            <b-row>
              <b-col>
                <b-form-group
                  label="Quote Sent By:"
                  label-for="quoteDateSent"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                <div id="quoteDateSent" class="alignWithLabel">
                {{this.leadQuote.displayName}}
                </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Quote Data Sent-->
          </b-form>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button variant="outline-danger" class="mr-1" @click="cancel">
        Close
      </b-button>

      <b-button v-if="!isDisableFields && !$v.$invalid"
      variant="success"
      class="mr-1"
      @click="saveQuote(true)">
       {{emailTemplateActive ? "Save without Sending" :"Save"}}
      </b-button>
      <b-button
      v-if="!isDisableButton && !$v.$invalid && emailTemplateActive"
        variant="secondary"
        @click="openEmailTemplateSender()">
        Save and Send Quote
        </b-button>
    </template>
  </b-modal>
  <EmailTemplateSender
    ref="quoteTemplateEditor"
    title="Modify Quote Before Sending"
    :emailTemplateID=7
    :initialToAddresses="selectedEmailAddresses"
    :templateBodyFields="quoteTemplateBodyFields"
    :emailCancelledCallback="emailQuoteCancelled"
    :emailSentCallback="sendQuote">
  </EmailTemplateSender>
</div>
</template>
<style lang="scss" scoped>
  .is-invalid {
    border: 1px solid #dc3545 !important;
  }
  .multiselect-is-invalid ::v-deep .multiselect__tags {
    border: 1px solid #dc3545 !important;
  }
  .alignWithLabel{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
  }
  .disable-textarea-resize{
    resize:none;
  }
</style>

<script>
import { validationMixin } from 'vuelidate'
import confirmation from '../Common/Confirmation.vue'
import LeadQuoteMixin from '../../mixins/LeadQuoteMixin.vue'
import EmailTemplateMixin from '../../mixins/EmailTemplateMixin.vue'
import EmailTemplateSender from './EmailTemplateSender.vue'
import Multiselect from 'vue-multiselect'
import Switches from 'vue-switches'
import moment from 'moment/moment.js'
import { createNamespacedHelpers, mapMutations } from 'vuex'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import ModalMessages from '../../containers/navs/ModalMessages.vue'
import { helpers } from 'vuelidate/lib/validators'

const currencyMask = createNumberMask({
  prefix: '$',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false
})

const { required, maxLength, minLength } = require('vuelidate/lib/validators')
const emailValidation = helpers.regex('emailValidation', /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

export default {
  name: 'leadQuoteModal',
  components: {
    multiselect: Multiselect,
    switches: Switches,
    Confirmation: confirmation,
    messages: ModalMessages,
    EmailTemplateSender: EmailTemplateSender
  },
  data () {
    return {
      leadQuoteID: 0,
      title: undefined,
      isVisible: false,
      isShow: false,
      options: [
        { text: 'No', value: false },
        { text: 'Yes', value: true }
      ],
      myRecipient: '',
      quoteTemplateBodyFields: [],
      currencyMask,
      recipientsSelected: [],
      emailTemplateActive: false
    }
  },
  async created () {
    await this.getEmailTemplateById(7)
    this.emailTemplateActive = this.emailTemplate.active
  },
  mixins: [LeadQuoteMixin, validationMixin, EmailTemplateMixin],
  validations: {
    leadQuote: {
      rfpRequired: {
        // required
      },
      maskedQuoteAmount: {
        required,
        minLength: minLength(2)
      },
      notes: {
        maxLength: maxLength(500)
      }
    },
    recipientsSelected: {
      required, // this validates that the user has selected at least one recipient.
      $each: { // These validations make sure they specify a name and contact info each time they click Add New Recipient
        email: {
          required,
          emailValidation
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setInfoMessage',
      'setDangerMessage',
      'setModalDangerMessage',
      'setModalWarningMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.leadQuoteID) {
        this.leadQuoteID = opts.leadQuoteID
        await this.getLeadQuote(this.leadQuoteID)
      }

      if (opts.companyID) {
        this.initNewQuote(opts.companyID)
      }

      this.loadQuoteRecipients()

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
    },
    hide () {
      // don't clear anything on hide.
      // this gets called when we set isVisible to false
      // which we have to do when we open the EmailTemplateSender modal
      // if we don't hide this modal, then the inputs of the secondary modal are forced into readonly mode.
    },
    close () {
      // This gets called when the modal is closed via the X in the top right or the "close" button.
      // We do want to clear data in this case.
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.leadQuoteID = 0
      this.recipientsSelected = []
      this.setModalWarningMessage('')
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async loadQuoteRecipients () {
      if (this.leadQuote.quoteDateSent !== null && this.leadQuote.quoteSentByID !== 0) {
        await this.getRecipientsByQuoteID(this.leadQuoteID)

        this.recipientsSelected = this.quoteRecipients = this.mapQuoteRecipients()
      } else {
        await this.getLeadQuoteRecipientsByCompanyID(this.leadQuote.companyID)

        this.quoteRecipients = this.mapQuoteRecipients()
      }
    },
    addSelectedRecipient () {
      if (this.myRecipient) {
        this.recipientsSelected.push(this.myRecipient)
        this.myRecipient = null
      }
    },
    addExternalRecipiant () {
      this.recipientsSelected.push({
        email: '',
        firstName: 'User'
      })
    },
    deleteEvent (index) {
      // var indexToBeDeleted = this.recipientsSelected.map(r => r.recipientID).indexOf(id)
      this.recipientsSelected.splice(index, 1)
    },
    mapQuoteRecipients () {
      return this.quoteRecipients.map((r) => {
        return {
          displayData: `${r.firstName} ${r.lastName} - ${r.email}`,
          firstName: r.firstName,
          lastName: r.lastName,
          email: r.email,
          recipientID: r.id,
          aspNetUserID: r.id,
          id: r.id
        }
      })
    },
    openEmailTemplateSender () {
      // get the templatebody fields based on the currently selected quote info.
      // All the functions to do this should be located in EmailTemplateMixin so there's only one place to change if new fields get added to a template.
      this.quoteTemplateBodyFields = this.createTemplateBodyFields_LeadQuote(this.leadQuote.maskedQuoteAmount)
      this.isVisible = false
      this.$refs.quoteTemplateEditor.show()
    },
    emailQuoteCancelled () {
      this.isVisible = true
      this.setModalWarningMessage('Quote email dialog cancelled. Quote not sent.')
    },
    // This callback gets hit when the user sends the email they edited in EmailTemplateSender
    async sendQuote (toAddresses, ccAddresses, subject, body, files) {
      // save changes to main quote info (amt, notes, active, contacts)
      await this.saveQuote(false)
      if (this.statusCode === 200) {
        // clear the message that the mixin set if we succeeded.
        // This fixes a bug where it was possible to get both a success message from this save AND an error message from the next operation
        this.setInfoMessage('')
      }
      var emailDTO = {
        aspNetUsers: this.recipientsSelected,
        allToAddresses: toAddresses,
        ccAddresses: ccAddresses,
        subject: subject,
        body: body,
        files: files
      }
      const users = emailDTO.aspNetUsers.map(item => item.aspNetUserID).join(',')
      this.$socket.invoke('SendMessage', users)
      await this.sendByEmailQuote(emailDTO, this.leadQuoteID)
      if (this.statusCode === 200) {
        this.close()
      } else {
        // only make this modal reappear if something went wrong on the save.
        this.isVisible = true
      }
    },

    // add new quote
    initNewQuote (companyID) {
      this.leadQuote = {
        companyID: companyID,
        rfpRequired: false,
        quoteDateSent: null,
        quoteSentByID: null,
        maskedQuoteAmount: '',
        quoteAmount: null,
        active: true,
        notes: null
      }

      this.okButton = 'Send'
    },
    async saveQuote (closeOnSuccess) {
      if (this.leadQuote.quoteID) {
        await this.updateQuote(this.leadQuote)
      } else {
        this.leadQuoteID = await this.addQuote(this.leadQuote)
      }

      // Close modal on successful save or update
      if (this.statusCode === 200 && closeOnSuccess) {
        await this.close()
      }
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      }
    }
  },
  computed: {
    date () {
      return this.formatDate(this.leadQuote.quoteDateSent)
    },
    isDisableButton () {
      if (!this.leadQuote.quoteDateSent && !this.leadQuote.quoteSentByID && this.recipientsSelected.length > 0) {
        return false
      }
      return true
    },
    isDisableFields () {
      if (this.leadQuote.quoteDateSent && this.leadQuote.quoteSentByID) {
        return true
      }
      return false
    },
    selectedEmailAddresses () {
      return this.recipientsSelected.map(function (item) {
        return item.email
      })
    }
  }
}
</script>
