<template>
  <FullPageModal ref="emailTemplateEditor" :closeCallback="cancelEmailTemplateSender" :title="title">
    <template v-slot:body>
      <b-form-group label="To Addresses">
        <div v-for="(email, index) in myToAddresses" :key="index">
          <b-row style="padding:3px">
              <b-colxx sm="6">
                <b-input
                    type="text"
                    v-model="myToAddresses[index]"
                    v-on:keyup.enter="addToAddress()"
                    :state="!$v.myToAddresses.$each[index].$error"
                    @input="$v.myToAddresses.$each[index].$touch"
                />
                <b-form-invalid-feedback v-if="$v.myToAddresses.$each[index].$error">
                  Must be a valid email
                </b-form-invalid-feedback>
              </b-colxx>
              <b-colxx sm="2" v-if="index < myToAddresses.length - 1">
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    @click="removeToAddress(index)">
                    Remove
                  </b-button>
              </b-colxx>
              <b-colxx sm="2" v-if="index == myToAddresses.length - 1">
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="addToAddress">
                    Add Another
                  </b-button>
              </b-colxx>
          </b-row>
        </div>
      </b-form-group>
      <b-form-group label="CC Addresses">
        <div v-for="(email, index) in myCCAddresses" :key="index">
          <b-row style="padding:3px">
            <b-colxx sm="6">
                <b-input
                    type="text"
                    v-model="myCCAddresses[index]"
                    v-on:keyup.enter="addCCAddress()"
                    :state="!$v.myCCAddresses.$each[index].$error"
                    @input="$v.myCCAddresses.$each[index].$touch"
                />
                <b-form-invalid-feedback v-if="$v.myCCAddresses.$each[index].$error">
                  Must be a valid email
                </b-form-invalid-feedback>
            </b-colxx>
            <b-colxx sm="2" v-if="index < myCCAddresses.length - 1">
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    @click="removeCCAddress(index)">
                    Remove
                  </b-button>
              </b-colxx>
            <b-colxx sm="2" v-if="index == myCCAddresses.length - 1">
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="addCCAddress">
                    Add Another
                  </b-button>
            </b-colxx>
          </b-row>
        </div>
      </b-form-group>
    <b-row>
        <b-colxx sm="6">
            <b-form-group label="Subject">
            <b-form-input
                type="text"
                v-model="mySubject"
                :state="!$v.mySubject.$error"
                @input="$v.mySubject.$touch"
            />
            <b-form-invalid-feedback v-if="!$v.mySubject.required"
                >Please enter Subject
              </b-form-invalid-feedback>
            <b-form-invalid-feedback
                v-else-if="!$v.mySubject.maxLength"
                >Maximum Characters Allowed 150
            </b-form-invalid-feedback>
            </b-form-group>
        </b-colxx>
    </b-row>
    <b-row>
        <b-colxx sm="6">
            <b-form-group label="Attachment(s)">
              <div class="dashboard-tiles mb-4">
                <b-card class="text-center card" >
                  <b-alert show variant="primary">Allowed File Types : <b> .doc, .docx, .xls, .xlsx, .pdf, .csv</b></b-alert>
                  <b-alert v-if="files.length == 6" show variant="warning">Maximum upload files count is six</b-alert>
                  <div class="example-drag force-center">
                    <div class="upload">
                      <div v-if="files.length">
                        <table class="table">
                          <tbody>
                            <tr v-for="(file, index) in files" :key="file.id">
                              <td>{{file.name}}</td>
                              <td>
                                <BIconTrash variant="danger"  @click="deleteFile(index)"></BIconTrash>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <td colspan="7">
                          <div class="text-center p-5">
                            <h4>Drop files anywhere to upload<br/>or</h4>
                            <label for="file" class="btn btn-lg btn-primary">Select File(s)</label>
                          </div>
                        </td>
                      </div>

                      <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                        <h3>Drop files to upload</h3>
                      </div>

                      <div v-show="files.length != 6" class="example-btn">
                        <file-upload
                          @input-filter="inputFilter"
                          accept=".doc, .docx, .xls, .xlsx, .pdf, .csv"
                          :maximum="6"
                          v-show="files.length"
                          class="btn btn-primary"
                          :multiple="true"
                          :drop="true"
                          v-model="files"
                          ref="upload">
                          Select File(s)
                        </file-upload>
                      </div>
                    </div>
                  </div>
                </b-card>
              </div>
            </b-form-group>
        </b-colxx>
    </b-row>
    <b-row>
      <b-colxx lg="12">
        <b-form-group label="Body">
        <editor
            api-key="no-api-key"
            v-model="myBody"
            :state="!$v.myBody.$error"
            :init="{
            height: 500,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | code | help'
            }"
        />
        </b-form-group>
        <b-form-invalid-feedback v-if="!$v.myBody.required">Please enter Body</b-form-invalid-feedback>
      </b-colxx>
    </b-row>
    </template>
    <template v-slot:footer>
      <b-row>
        <b-col sm="12" md="4" xl="3">
          <b-button
            block
            variant="danger"
            size="lg"
            class="mt-4"
            @click="cancelEmailTemplateSender()">
            {{ $t("forms.cancel") }}
          </b-button>
        </b-col>
        <b-col sm="12" md="4"  xl="3">
          <b-button
            block
            variant="success"
            size="lg"
            class="mt-4"
            @click="sendModifiedEmail()">
            {{ $t("forms.send-email") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </FullPageModal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import EmailTemplateMixin from '../../mixins/EmailTemplateMixin.vue'
import fullPageModal from '../Common/FullPageModal.vue'
import Editor from '@tinymce/tinymce-vue'
import FileUpload from 'vue-upload-component'
import { BIcon, BIconTrash } from 'bootstrap-vue'

const {
  required,
  maxLength,
  email
} = require('vuelidate/lib/validators')

export default {
  components: {
    editor: Editor,
    FullPageModal: fullPageModal,
    FileUpload,
    // BIcon,
    BIconTrash
  },

  props: {
    title: {
      type: String,
      default: 'Send Email'
    },
    emailTemplateID: {
      type: Number,
      required
    },
    emailSentCallback: {
      type: Function,
      required
    },
    emailCancelledCallback: {
      type: Function,
      required
    },
    initialToAddresses: {
      type: Array,
      required
    },
    templateBodyFields: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data: () => {
    return {
      isVisible: false,
      // names are prefixed with 'my' just to help differentiate them in Vue devtools from similarly named variables in the EmailTemplateMixin.
      myToAddresses: [''],
      myCCAddresses: [''],
      mySubject: '',
      myBody: '',
      files: []
    }
  },
  mixins: [validationMixin, EmailTemplateMixin],
  validations: {
    mySubject: {
      required,
      maxLength: maxLength(150)
    },
    myToAddresses: {
      $each: {
        email
      }
    },
    myCCAddresses: {
      $each: {
        email
      }
    },
    myBody: {
      required
    }
  },
  methods: {
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile) {
        if (!/\.(doc|docx|xls|xlsx|pdf|csv)$/i.test(newFile.name)) {
          alert('Invalid file! Please upload only allowed file types.')
          return prevent()
        }
        const inMb = parseFloat(newFile.size) / parseFloat(2000000)
        if (inMb > 2) {
          alert('Maximum upload file size is 2MB')
          return prevent()
        }
      }
      // Create a blob field
      newFile.blob = ''
      var URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    },
    async show () {
      await this.getEmailTemplateById(this.emailTemplateID)
      this.mySubject = this.emailTemplate.subject
      this.myBody = this.emailTemplate.body
      this.replaceTemplateFieldsWithValues()

      // This may seem strange. We want initialToAddresses to be a property so calling code knows it is supposed to set the value.
      // But properties are not reactive, so we simply copy the initial value into this component's data so it will become reactive.
      if (this.initialToAddresses === []) {
        this.myToAddresses = ['']
      } else {
        this.myToAddresses = this.initialToAddresses
      }

      this.$refs.emailTemplateEditor.openModal()
    },
    async sendModifiedEmail () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$refs.emailTemplateEditor.closeModal()
        await this.emailSentCallback(this.myToAddresses, this.myCCAddresses, this.mySubject, this.myBody, this.files)
        this.clearData()
      }
    },
    cancelEmailTemplateSender () {
      this.$refs.emailTemplateEditor.closeModal()
      this.emailCancelledCallback()
      this.clearData()
    },
    replaceTemplateFieldsWithValues () {
      this.templateBodyFields.forEach(tbf => {
        this.myBody = this.myBody.replace(`${tbf.fieldName}`, `${tbf.fieldValue}`)
      })
    },
    addToAddress () {
      this.myToAddresses.push('')
    },
    removeToAddress (index) {
      this.myToAddresses.splice(index, 1)
    },
    addCCAddress () {
      this.myCCAddresses.push('')
    },
    removeCCAddress (index) {
      this.myToAddresses.splice(index, 1)
    },
    clearData () {
      this.myToAddresses = ['']
      this.myCCAddresses = ['']
      this.subject = ''
      this.body = ''
    },
    deleteFile (index) {
      this.files.splice(index, 1)
    }
  },
  computed: {}
}
</script>

<style scoped>
  .form-control.is-invalid {
    border-color: #dc3545;
  }
  .modal-fullscreen .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
}

.example-drag label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.example-drag .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.force-center{
  text-align: -webkit-center;
}
</style>
