<template>
  <div>
    <Confirmation ref="Confirmation"></Confirmation>
    <LeadQuoteModal ref="LeadQuoteModal"></LeadQuoteModal>
    <datatable-heading
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="searchChange"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :titleAndButton="true"
    >
    <template v-slot:button>
        <div class="title-and-right-element">
            <PageTitle :title="'Quote'" :identifier="'Company #'+ companyID"></PageTitle>
         <b-button
            variant="primary"
            size="lg"
            class="top-right-button mr-2"
            @click="addQuote()"
          >Add New</b-button>
        </div>
    </template>
    </datatable-heading>

    <b-row>
      <b-colxx xxs="12">
        <b-card>
          <vuetable
            ref="vuetable"
            track-by="quoteID"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
            :reactive-api-url="false"
            :fields="fields"
            :css="css.table"
            pagination-path
            :row-class="onRowClass"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="rowClicked"
            @vuetable:cell-rightclicked="rightClicked"
            @vuetable:load-error="handleLoadError"
          >

          <template slot="truncatedNotes" slot-scope="props">
            <read-more v-if="props.rowData.notes" class="read-more" more-str="read more" :text="props.rowData.notes" less-str="read less" :max-chars="100"></read-more>
          </template>

           <template slot="active" slot-scope="props">
              <div style="display:flex;justify-content:center;align-items;center;">
                <ToggleSwitch
                  v-on:input="flipActive(props.rowData)"
                  v-model="props.rowData.active"
                  :emitOnMount="false"
                  theme="custom"
                  class="vue-switcher-small"
                  color="primary"
                  :id="props.rowData.active"
                  v-b-tooltip.hover.left :title=" props.rowData.active ? 'Deactivate' : 'Activate'"
                />
              </div>
            </template>
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
          class="mt-4"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
      </b-colxx>
    </b-row>

    <v-contextmenu ref="contextmenu">
       <v-contextmenu-item @click="onContextMenuAction('edit-view','edit')" v-if="selectedRecord && !selectedRecord.quoteDateSent">
        <i class="simple-icon-docs" />
        <span>Edit</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('edit-view','view')" v-else>
        <i class="simple-icon-docs" />
        <span>View</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('activate')">
        <i class="simple-icon-docs" />
        <span>Activate</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('deactivate')">
        <i class="simple-icon-docs" />
        <span>Deactivate</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>

<script>
import Confirmation from '../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import Switches from 'vue-switches'
import { BeaconapiUrl } from '../../../constants/config'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import LeadQuoteModal from '../../../components/Modals/LeadQuoteModal.vue'
import LeadQuoteMixin from '../../../mixins/LeadQuoteMixin.vue'
import ReadMore from '../../../../node_modules/vue-read-more/components/ReadMoreComponent.vue'
import moment from 'moment/moment.js'
import _ from 'lodash'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'

// so we can set our messages in the layout if needed
import { mapMutations, mapActions } from 'vuex'
export default {
  props: {
    companyID: {
      type: Number,
      default: 0
    }
  },

  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: Confirmation,
    LeadQuoteModal: LeadQuoteModal,
    ToggleSwitch: Switches,
    'read-more': ReadMore,
    PageTitle: ParentEntityPageTitle
  },
  mixins: [LeadQuoteMixin],
  data () {
    // because the id field is used in other places throughout this page here it should be
    // identified as a variable so that we don't have it hardcoded everywhere
    var _idfield = 'quoteID'
    return {
      isLoad: false,
      apiBase: BeaconapiUrl + `/api/lead/quote/table/${this.companyID}`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,

      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right',
          width: '5%'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Quote ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        {
          name: 'quoteAmount',
          sortField: 'quoteAmount',
          title: 'Quote Amount',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          callback: this.formatMoney
        },
        {
          name: '__slot:truncatedNotes',
          title: 'Notes',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        {
          name: 'quoteDateSent',
          sortField: 'quoteDateSent',
          title: 'Quote Date Sent',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          callback: this.formatDate
        },
        {
          name: 'displayName',
          sortField: 'sentBy',
          title: 'Quote Sent By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        // {
        //   name: 'rfpRequired',
        //   sortField: 'rfpRequired',
        //   title: 'RFP Required',
        //   titleClass: '',
        //   dataClass: 'text-muted',
        //   width: '10%'
        // },
        {
          name: '__slot:active',
          sortField: 'active',
          title: 'Active',
          titleClass: 'center aligned text-center',
          dataClass: 'center aligned text-right',
          width: '10%'
        }
      ],
      selectedRecord: null
    }
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),
    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
      this.selectedRecord = dataItem
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(x => x !== itemId)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
      }
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    // End of Standard Vue Table methods

    // Component-specific Vue Table methods
    onContextMenuAction (action, actionType) {
      var rightClickedItem = this.rightClickedItem
      var matchedItems = this.$refs.vuetable.tableData.filter(item => this.selectedItems.includes(item.quoteID))
      var quotes = matchedItems.map(quote => quote.quoteID).join(',')
      switch (action) {
        case 'activate':
        {
          this.$refs.Confirmation.show({
            title: 'Activate Quote',
            message: `Are you sure you want to activate the following quotes: ${quotes}`,
            okButton: 'Activate'
          }).then((result) => {
            if (result) {
              this.activateItems(true, matchedItems)
            }
          })
          break
        }
        case 'deactivate':
        {
          this.$refs.Confirmation.show({
            title: 'Deactivate Quote',
            message: `Are you sure you want to deactivate the following quotes: ${quotes}?`,
            okButton: 'Deactivate'
          }).then((result) => {
            if (result) {
              this.activateItems(false, matchedItems)
            }
          })
          break
        }
        case 'edit-view':
        {
          if (this.$refs.vuetable.selectedTo.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: `You may only ${actionType} one item at a time`,
              okButton: 'Cancel',
              exitFunction: (e) => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.$refs.vuetable.refresh()
                }
              }
            })
          } else {
            this.$refs.LeadQuoteModal.show({
              title: `${(actionType === 'edit') ? 'Edit' : 'View'} Quote: ${this.$refs.vuetable.selectedTo[0]}`,
              leadQuoteID: this.$refs.vuetable.selectedTo[0],
              exitFunction: () => {
                this.$refs.vuetable.refresh()
              }
            })
          }
          break
        }
        default:
          break
      }
    },
    // End of Component-specific Vue table methods

    // Component-specific methods
    flipActive (quote) {
      this.$refs.Confirmation.show({
        title: quote.active ? 'Activate Quote' : 'Deactivate Quote',
        message: 'Are you sure you want to ' + (quote.active ? 'activate' : 'deactivate') + ` Quote ID ${quote.quoteID}?`,
        okButton: 'Yes',
        exitFunction: (e) => {
          if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
            this.refreshVueTable()
          }
        }
      }).then(async (result) => {
        if (result) {
          await this.updateQuote(quote)
            .then(async () => {
              this.setInfoMessage(`Quote ID ${quote.quoteID} has been ` + (quote.active ? 'activated' : 'deactivated'))
              this.refreshVueTable()
            })
        }
      })
    },
    async activateItems (activateBool, matchedItems) {
      var activateQuoteList = matchedItems.map(quote => ({ ID: quote.quoteID, Active: activateBool }))
      await this.activateQuotes(activateQuoteList)
        .then(() => {
          this.refreshVueTable()
        })
    },
    // End of Component-specific methods
    addQuote () {
      this.$refs.LeadQuoteModal.show({
        title: 'Create New Quote',
        companyID: this.companyID,
        exitFunction: () => {
          this.$refs.vuetable.refresh()
        }
      })
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      }
    },
    formatMoney (value) {
      if (value === '') {
        return ''
      } else {
        return value.toLocaleString('en-US',
          {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
          })
      }
    }
  }
}
</script>
