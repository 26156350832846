<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import moment from 'moment/moment.js'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      leadQuote: {},
      states: [],
      addressTypes: [],
      quoteRecipients: [],
      quoteInfo: {},
      quoteUpdate: {},
      statusCode: null,
      errorMessage: null
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'setModalDangerMessage',
      'setModalAlertMessage',
      'setModalInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getLeadQuote (quoteID) {
      await axios
        .get(`${BeaconapiUrl}/api/lead/quote/${quoteID}`)
        .then((result) => {
          this.leadQuote = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getLeadQuoteRecipientsByCompanyID (companyID) {
      return await axios
        .get(`${BeaconapiUrl}/api/lead/quote/recipient/by-company/${companyID}`)
        .then((result) => {
          this.quoteRecipients = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getRecipientsByQuoteID (quoteID) {
      await axios
        .get(`${BeaconapiUrl}/api/lead/quote/recipient/${quoteID}`)
        .then((result) => {
          this.quoteRecipients = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async sendByEmailQuote (recipients, quoteID) {
      const employeeID = localStorage.getItem('employeeID')
      const companyID = localStorage.getItem('companycontext')
      const form = new FormData()
      for (var i = 0; i < recipients.files.length; i++) {
        const file = recipients.files[i]
        form.append('file', file.file)
      }
      form.append('emailDTO', JSON.stringify(recipients))
      await axios
        .post(`${BeaconapiUrl}/api/lead/quote/send`, form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params: {
            companyID: companyID,
            empID: employeeID,
            quoteID: quoteID
          }
        })
        .then((result) => {
          this.statusCode = result.status
          // Quotes are sent from a modal dialog that closes on success.
          this.setInfoMessage('Quote Successfully Sent!')
        })
        .catch((error) => {
          this.statusCode = error.status
          // modal stays open on error, so display message there
          this.setModalDangerMessage(`Error - Quote did not send. ${error.response.data}`)
        })
    },
    async updateQuote (quote) {
      quote.lastModifiedByID = localStorage.getItem('employeeID')
      quote.quoteAmount = this.removeQuoteAmountMask(quote.maskedQuoteAmount)
      await axios
        .put(`${BeaconapiUrl}/api/lead/quote`, quote)
        .then((result) => {
          this.statusCode = result.status
          // Quotes are updated in a modal dialog that closes on success.
          this.setInfoMessage('Quote Successfully Saved!')
        })
        .catch((error) => {
          this.statusCode = error.status
          // modal stays open on error, so display message there
          this.setModalDangerMessage(error.message)
        })
    },
    async addQuote (jsonQuote) {
      // jsonQuote.createdByID = localStorage.getItem('employeeID')
      jsonQuote.quoteAmount = this.removeQuoteAmountMask(jsonQuote.maskedQuoteAmount)
      return await instance
        .post(`${BeaconapiUrl}/api/lead/quote`, jsonQuote)
        .then((result) => {
          this.statusCode = result.status
          // Quotes are added in a modal dialog that closes on success.
          this.setInfoMessage('Quote Successfully Saved!')
          return result.data
        })
        .catch((error) => {
          this.statusCode = error.status
          // modal stays open on error, so display message there
          this.setModalDangerMessage(error.message)
        })
    },
    async activateQuotes (quotes) {
      await axios
        .put(`${BeaconapiUrl}/api/lead/quote/activate`, quotes)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.appendDangerMessage(error.message)
        })
    },
    removeQuoteAmountMask (maskQuoteAmount) {
      var quoteAmount = maskQuoteAmount.replace(/\$|,/g, '')
      return quoteAmount
    }
  },
  formatDate (value) {
    if (value === null) {
      return ''
    } else {
      return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
    }
  }
}
</script>
